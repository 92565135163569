import { gql } from "@apollo/client";

export const RSVP_QUERY = gql`
query {
  rsvp(id: "5KEvDT25xeBQYTnu108SuP") {
    adultStarterCollection {
      items {
        key
        description
      }
    }
    adultMainCollection {
      items {
        key
        description
      }
    }
    adultDesertCollection {
      items {
        key
        description
      }
    }
  	childStarterCollection {
      items {
        key
        description
      }
    }
    childMainCollection {
      items {
        key
        description
      }
    }
    childDesertCollection {
      items {
        key
        description
      }
    }
  }
}
`