import React, { FC } from "react";

import classnames from "classnames";
import commonStyles from "../../style/common.css";
import styles from "./itinerary.css";

import { ITINERARY_QUERY } from './itinerary.query'
import { useQuery } from "@apollo/client";

const formatAMPM = (date: string) => {
  const dateFormatted = new Date(date)
  var hours = dateFormatted.getHours();
  var minutes: number | string = dateFormatted.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

const printDate = (date: string) => {
  const dateFormatted = new Date(date)
  console.log("__________")
  console.log("__________")
  console.log(dateFormatted)
  
  dateFormatted.getHours()
  return (
    <div>{formatAMPM(date)}</div>
  )
}
export const HomeView: FC = ({}) => {
  const { loading, error, data } = useQuery(ITINERARY_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;   


  const date1 = new Date();

  return (
    <>
      <div className={classnames(styles?.block)} id={"itinerary"}>
        <div
          className={classnames(
            commonStyles?.container__large,
            styles?.itinerary
          )}
        >
          <div
            className={classnames(styles?.["table-container"])}
            role="table"
            aria-label="Destinations"
          >
            <div className={classnames(styles?.["flex-table"], styles?.header)} role="rowgroup">
              <div className={classnames(styles?.["flex-row"])} role="columnheader">
                Whats on
              </div>
              <div className={classnames(styles?.["flex-row"])} role="columnheader">
                When
              </div>
            </div>

            {data?.itinerary?.itineraryItemsCollection?.items?.map(item => (
              <div className={classnames(styles?.["flex-table"], styles?.header)} role="rowgroup">
                <div className={classnames(styles?.["flex-row"])} role="columnheader">
                  {item?.whatsOn}
                </div>
                <div className={classnames(styles?.["flex-row"])} role="columnheader">
                  {}              {printDate(item?.start)}
                </div>

              </div>
            ))}
          </div>
        </div>

      </div>
    </>
  );
};

export default HomeView;
