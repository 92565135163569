import {
  gql
} from "@apollo/client";

export const VENUE_QUERY = gql`
query {
  venue(id: "1c7ui1D4CFSs4VVaEXnW8o") {
    location {
      name
      query
      link
    }
    description {
      json
    }
  }
}
`