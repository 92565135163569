import React, { FC } from 'react';
import { useQuery } from "@apollo/client";

import { renderCommonContentType } from '../../render'
import classnames from 'classnames'
import styles from '../../style/common.css';
import { VENUE_QUERY } from './venue.query'
import venueStyles from './venue.css';

export const HomeView: FC = ({}) => {
  const { loading, error, data } = useQuery(VENUE_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
      <>
        <div className={classnames(venueStyles?.venue, venueStyles?.block)} id={'venue'}>
          <div className={styles?.container__large}>
            <div className={venueStyles?.venue__block}>
              <div>{renderCommonContentType(data?.venue?.description)}</div>
              <div><a href={data?.venue?.location?.link} className={venueStyles?.punchoutLink}>Click here to visit website</a></div>
              <iframe src={data?.venue?.location?.query} scrolling="no" className={venueStyles?.venue__frame}/>
            </div>
          </div>
        </div>
      </>
    );
}

export default HomeView;