import { gql } from "@apollo/client";

export const GIFTS_QUERY = gql`
query {
  gifts(id: "1GmfwcKlk2RSWw5iObDUfU") {
    description {
      json
    }
  }
}
`