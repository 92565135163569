import { useState } from 'react';

export const useInput = (initialValue: string, changeAction?: Function, key?: string) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        console.log("_________________")
        console.log("event")
        console.log(event.target.value)
        setValue(event.target.value);
        if(changeAction) changeAction(event.target.value, key)
      }
    }
  };
};