import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import HomeViewContainer from './containers/home/home';
import RsvpViewContainer from './containers/rsvp/rsvp';
import VenueViewContainer from './containers/venue/venue';
import MusicViewContainer from './containers/music/music';
import AccommodationViewContainer from './containers/accommodation/accommodation';
import GiftsViewContainer from './containers/gifts/gifts';
import NotFoundComponent from './containers/notFound/notFound';
import Itinerary from './containers/itinerary/itinerary'
import { Header } from './containers/header/Header'

interface StateProps {}
interface ActionProps {}
interface SelectorProps {}

type Props = StateProps & ActionProps & SelectorProps;

class AppRouter extends React.Component<Props> {
  render() {
    return (
      <>
        <Helmet>
          <title>Alex And Stephanie Wedding</title>
          <meta
            name="description"
            content="Alex And Stephanie Wedding"
          />
          <meta
            name="og:title"
            property="og:title"
            content="Alex And Stephanie Wedding"
          />
          <meta property="og:type" content="website" />
          <meta name="robots" content="index, follow" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        </Helmet>

        <Header/>
        <Switch>
          <Route exact path="/home" component={HomeViewContainer} />
          <Route exact path="/rsvp" component={RsvpViewContainer} />
          <Route exact path="/accommodation" component={AccommodationViewContainer} />
          <Route exact path="/itinerary" component={Itinerary} />
          <Route exact path="/" component={Itinerary} />
          <Route exact path="/venue" component={VenueViewContainer} />
          <Route exact path="/music" component={MusicViewContainer} />
          <Route exact path="/gifts" component={GiftsViewContainer} />
          <Route component={NotFoundComponent} />
        </Switch>
    
      </>
    );
  }
}

export default AppRouter;
