import React, { FC } from 'react';
import { useQuery } from "@apollo/client";
import classnames from 'classnames'
import { renderCommonContentType } from '../../render'

import { MUSIC_QUERY } from './music.query';
import styles from '../../style/common.css';
import homeStyles from './music.css';

export const AccomodationView: FC = ({}) => {
  const { loading, error, data } = useQuery(MUSIC_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
      <>
        <div className={classnames(homeStyles?.accommodation, homeStyles?.block)} id={'accommodation'}>
          <div className={styles?.container__large}>
            <div>
              {renderCommonContentType(data?.music?.description)}
            </div>
            <div className={homeStyles?.accommodation__block}>
              <iframe src={data?.music?.embed} scrolling="no" className={homeStyles?.accommodation__frame}/>
            </div>
          </div>
        </div>

      </>
    );
}

export default AccomodationView;