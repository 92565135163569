import React, { useState } from 'react';
import classnames from 'classnames';

import { Link } from '../../components/link/link';
import styles from './Header.css';

export const Header: React.FC<{}> = ({}) => {
    const [isOpen, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    console.log(activeTab)
    return (
     <>
        <div className={classnames(styles?.header__container)}></div>
            <header className={classnames(styles?.header)}>
                <div className={classnames(styles?.header__container)}>
                    <div onClick={()=> setOpen(!isOpen)} className={classnames(styles['MenuToggle'], isOpen ? styles['MenuToggle--Open'] : styles['MenuToggle--Closed'])}>
                        <span className={classnames(styles['MenuToggle--Line'])}></span>
                        <span className={classnames(styles['MenuToggle--Line'])}></span>
                        <span className={classnames(styles['MenuToggle--Line'])}></span>
                    </div>
                    <div className={styles?.header__logo}><h1 className={styles?.['header__logo--heading']}>Alex and Stephanie</h1></div>
                    <ul className={classnames(styles['Menu'], isOpen ? styles['Menu--Open'] : styles['Menu--Closed'] )}>
                        <li className={classnames(styles['Link'])}><Link classNames={activeTab === 0 ? styles?.menu__selected : undefined} to={'/'} onClick={() => { setOpen(false); setActiveTab(0) } }>Home</Link></li>
                        <li className={classnames(styles['Link'], styles['Link--left'], activeTab === 1 ? styles['Menu--Selected'] : undefined)}><Link classNames={activeTab === 1 ? styles?.menu__selected : undefined} to={'/venue'} onClick={() => { setOpen(false); setActiveTab(1) } }>Venue</Link></li>
                        <li className={classnames(styles['Link'], styles['Link--left'], activeTab === 2 ? styles['Menu--Selected'] : undefined)}><Link classNames={activeTab === 2 ? styles?.menu__selected : undefined} to={'/accommodation'} onClick={() => { setOpen(false); setActiveTab(2) } }>Accommodation</Link></li>
                        <li className={classnames(styles['Link'], styles['Link--left'], activeTab === 3 ? styles['Menu--Selected'] : undefined)}><Link classNames={activeTab === 3 ? styles?.menu__selected : undefined} to={'/itinerary'} onClick={() => { setOpen(false); setActiveTab(3) } }>Itinerary</Link></li>
                        <li className={classnames(styles['Link'], styles['Link--left'], activeTab === 4 ? styles['Menu--Selected'] : undefined)}><Link classNames={activeTab === 4 ? styles?.menu__selected : undefined} to={'/music'} onClick={() => { setOpen(false); setActiveTab(4) } }>Music</Link></li>
                        <li className={classnames(styles['Link'], styles['Link--left'], activeTab === 5 ? styles['Menu--Selected'] : undefined)}><Link classNames={activeTab === 5 ? styles?.menu__selected : undefined} to={'/gifts'} onClick={() => { setOpen(false); setActiveTab(5) } }>Gifts</Link></li>
                        <li className={classnames(styles['Link'], styles['Link--left'], activeTab === 6 ? styles['Menu--Selected'] : undefined)}><Link classNames={activeTab === 6 ? styles?.menu__selected : undefined} to={'/rsvp'} onClick={() => { setOpen(false); setActiveTab(6)} }>Day Guests</Link></li>
                    </ul>
                </div>
            </header>
        </>
    )
}