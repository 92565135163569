import React, { FC } from 'react';
import { useQuery } from "@apollo/client";
import classnames from 'classnames'

import { ACCOMMODATION_QUERY } from './accommodation.query'
import { renderCommonContentType } from '../../render'

import styles from '../../style/common.css';
import homeStyles from './accommodation.css';

export const AccomodationView: FC = ({}) => {
  const { loading, error, data } = useQuery(ACCOMMODATION_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  
  return (
      <div className={classnames(homeStyles?.accommodation, homeStyles?.block)} id={'accommodation'}>
        <div className={styles?.container__large}>
          <div>
            <div>{renderCommonContentType(data?.accommodation?.description)}</div>
            {data?.accommodation?.venuesCollection?.items?.map(venue => (
              <div className={homeStyles?.accommodation__block}>
                <iframe src={venue?.query} scrolling="no" className={homeStyles?.accommodation__frame}/>
                <div>
                  <a href={venue?.link} className={homeStyles?.punchoutLink}>Click here to book</a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
}

export default AccomodationView;