import { gql } from "@apollo/client";

export const ITINERARY_QUERY = gql`
query {
	itinerary(id:"4XOPJcUkmEnUMpFbFx4IfU") {
    title
    startTitle
    whoTitle
    whatsOnTitle
    itineraryItemsCollection {
      items {
        start
        who
        whatsOn
      }
    }
  }
}
`