import { gql } from "@apollo/client";

export const ACCOMMODATION_QUERY = gql`
query {
  accommodation(id: "1vKtpN2sZbAP4shSuS310B") {
    name
    description {
        json
    }   
    venuesCollection {
      items {
        name
        query
        link
      }
    }
  }
}
`