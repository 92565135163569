import { gql } from "@apollo/client";

export const MUSIC_QUERY = gql`
query {
	music(id: "34zA4q2aU7J7rqtouJ8XFp") {
    description {
    	json
    }
    embed
  }
}
`