import React, { FC, useState, FormEvent, useRef } from 'react';
import classnames from 'classnames';
import { useQuery } from "@apollo/client";
import { useInput } from '../../hooks/input'

import { GuestView } from './guest'
import { Guest } from '../../../models/guest'

import { RSVP_QUERY } from './rsvp.query'
import buttonStyles from '../../style/button.css'
import styles from '../../style/common.css';

import rsvpStyles from './rsvp.css';

//

const superSecretCode = "1825"

export const RsvpForm: FC = ({}) => {

  const { value: email, bind: bindEmail, reset: resetEmail } = useInput('');
    
  const [guests, setGuests] = useState<{guest: Guest, errors: string[]}[]>([]);
  const [submitted, isSubmitted] = useState(false);
  const divRef = React.useRef<HTMLDivElement>(null);
  const executeScroll = () => divRef?.current?.scrollIntoView()    

  const { loading, error, data } = useQuery(RSVP_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if(submitted) return <div className={rsvpStyles?.submittted}>Your RSVP has been sucessfully sumbmitted</div>
  
  const onSubmit = () => {
    const payload = {
      email: email,
      secret: superSecretCode,
      guests: guests?.map(item => item.guest)
    }
    // POST request using fetch inside useEffect React hook
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
    };
    fetch('https://m4zgzhd2yi.execute-api.eu-west-2.amazonaws.com/dev/todo', requestOptions)
        .then(response => response.json())
        .then(data =>{ if(data?.created === true) isSubmitted(true) });
  
  // empty dependency array means this effect will only run once (like componentDidMount in classes)
  };

  const addEmptyGuest = (type: "child" | "adult") => {
    setGuests([{ guest: { type, name: "", starter: "default", main: "default", desert: "default", requirements: ""}, errors: []}, ...guests])
  }

  const handleSubmit = (evt: FormEvent) => {
      evt.preventDefault();
      let shouldSubmit = true;
      const processedGuests = guests.map((item, i) => {
        const errors: string[] = []
        if (item.guest.starter === 'default') errors.push('starter')
        if (item.guest.main === 'default') errors.push('main')
        if (item.guest.desert === 'default') errors.push('desert')
        if(errors.length > 0) {
          shouldSubmit = false
          guests[i].errors = errors
        }
        return {
          ...item,
          errors
        }
      })
      setGuests(processedGuests)
      if(shouldSubmit) onSubmit()
  }

  const handleGuest = (index: number, guest: Guest) => {
    const newGuest = guests
    newGuest[index].guest = guest
    const processedGuests = newGuest.map((item, i) => {
      const errors: string[] = []
      if (item.guest.starter === 'default') errors.push('starter')
      if (item.guest.main === 'default') errors.push('main')
      if (item.guest.desert === 'default') errors.push('desert')
      if(errors.length > 0) {
        guests[i].errors = errors
      }
      return {
        ...item,
        errors
      }
    })
    setGuests(processedGuests)  }

  const deleteGuest = (index: number) => {
    let filteredGuests = guests.filter((pred, i) => {
      console.log("i, ", i)
      return i != index
    })
    setGuests(filteredGuests)
  }

  return (
    <div className={styles?.container__form}>
      <form className={rsvpStyles?.rsvpForm} onSubmit={handleSubmit}>

        <div>
          <label className={classnames(rsvpStyles?.label)}>Your Email :</label>
          <input className={classnames(rsvpStyles?.input)} type="email" {...bindEmail} />
        </div>

        <div className={rsvpStyles?.['buttons-container']}>
          <button className={classnames(rsvpStyles?.['buttons-container__button'], buttonStyles?.button)} onClick={() => addEmptyGuest("adult")}>Add Adult Guest</button>
          <button className={classnames(rsvpStyles?.['buttons-container__button'], buttonStyles?.button)} onClick={() => addEmptyGuest("child")}>Add Child Guest</button>
        </div>

        {
          guests && guests.map((guest, index) => {
            if(guest.guest.type === 'adult') return <GuestView type={"adult"} index={index} errors={guest.errors} guest={guest.guest} handleOnChange={handleGuest} deleteGuest={deleteGuest} options={{ starter: data?.rsvp?.adultStarterCollection?.items, main: data?.rsvp?.adultMainCollection?.items, desert: data?.rsvp?.adultDesertCollection?.items }}/>
            else return <GuestView type={"child"} index={index} guest={guest.guest} errors={guest.errors}  handleOnChange={handleGuest} deleteGuest={deleteGuest} options={{ starter: data?.rsvp?.childStarterCollection?.items, main: data?.rsvp?.childMainCollection?.items, desert: data?.rsvp?.childDesertCollection?.items }}/>
            }
          )
        }
        
        {
          guests.length > 0 && (
            <div className={rsvpStyles?.right}>
            <input className={classnames(buttonStyles?.button)} type="submit" value="Submit" />
          </div>
          )
        }
    
      </form>
    </div>
  );
}

///
export const RsvpView = () => {
  const { value: code, bind: bindCode, reset: resetCode } = useInput('');
  
  const [isAuthorized, setAuthorized] = useState(false);
  const [isBadSecret, setBadSecret] = useState(false);

  const submitCode = (e) => {
    if(code === superSecretCode) setAuthorized(true)
    setBadSecret(true)
  }

  if(!isAuthorized){
    return (
      <div className={styles?.container__form}>
        <form className={rsvpStyles?.rsvpForm}>

          <div>
            <label className={classnames(rsvpStyles?.label)}>Your code :</label>
            <input className={classnames(rsvpStyles?.input)} type="text" {...bindCode} />
            {isBadSecret &&
              <div className={rsvpStyles.bad__selection}>You have not entered a valid code</div>
            }
          </div>

          <div className={rsvpStyles?.submit__form}>
            <button className={classnames(rsvpStyles?.['buttons-container__button'], buttonStyles?.button)} onClick={(e) => { e.preventDefault(); submitCode(e)}}>Click here to RSVP</button>
          </div>
      
        </form>
      </div>
    );
  } else {
    return <RsvpForm/>
  }
}

export default RsvpView;