import React, { FC } from 'react';
import { useQuery } from "@apollo/client";

import { HOME_QUERY } from './home.query'
import { IHome } from './home.state';
import styles from '../../style/common.css';
import homeStyles from './home.css';

export const HomeView: FC<IHome> = ({}) => {
  const { loading, error, data } = useQuery(HOME_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  console.log(data)

  return (
      <div className={homeStyles?.margin__container}>
        <div className={styles?.container__large}>
          <div className={homeStyles?.hero}></div>
        </div>
      </div>
    );
}

export default HomeView;