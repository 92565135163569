import React, { FC } from 'react';

import classnames from 'classnames'
import { GIFTS_QUERY } from './gifts.query'
import commonStyles from '../../style/common.css';
import giftStyles from './gifts.css';
import { useQuery } from '@apollo/client';
import { renderCommonContentType } from '@web/render';

export const HomeView: FC = ({}) => {
  const { loading, error, data } = useQuery(GIFTS_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  
  return (
      <>
        <div className={classnames(giftStyles?.block)} id={'gifts'}>
          <div className={classnames(commonStyles?.container__large)}>
          <div className={giftStyles?.venue__block}>
            {renderCommonContentType(data?.gifts?.description)}
            </div>
          </div>
        </div>
      </>
    );
}

export default HomeView;