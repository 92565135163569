import { gql } from "@apollo/client";

export const HOME_QUERY = gql`
query {
  home(id: "7I6DsnZ7kXOFUBstPci8Zg") {
    description {
      json
    }
  }
}
`