import React from 'react';

export const getType = (marks: { type: string }[] | undefined): string => {
    if (!marks) return 'text'
    if(marks.find((item) => item.type === 'bold')) return 'bold';
    if(marks.find((item) => item.type === 'code')) return 'code'
    return 'text'
}

export const renderCommonContentType = (content): (JSX.Element) => { 
    console.log(content?.json?.content)
    const payload = content?.json?.content?.map(payload => payload.content?.map((inner) => {    
        if (inner.nodeType === 'list-item') return <span>{JSON.stringify(inner.content)}</span>
        if(getType(inner.marks) === 'code') return <pre>{inner.value}</pre>   
        if(getType(inner.marks) === 'bold') return <b>{inner.value}</b>                         
        return <span>{inner.value}</span>   
    }))
    return (
        <div style={{ fontSize: '2rem', marginTop: '2rem', marginBottom: '2rem' }}>
            {payload.map(item => {
                return (
                    <p>
                        {item}
                    </p>
                )
            })}
        </div>
    ) 
}
