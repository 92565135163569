import React, { FC, useState } from 'react';
import classnames from "classnames"
import { useInput } from '../../hooks/input'

import { Guest } from '../../../models/guest'

import rsvpStyles from './rsvp.css';

//

type GuestFunction = (index: number, guest: Guest) => void;
type DeleteGuestFunction = (index: number) => void;
type Props = { handleOnChange: GuestFunction, type: "adult" | "child", guest: Guest, errors: string[], index: number, deleteGuest: DeleteGuestFunction, options: { starter: { key: string, description: string }[], main: { key: string, description: string }[], desert: { key: string, description: string }[] } }

export const GuestView: FC<Props> = ({ index, handleOnChange, deleteGuest, guest, options, errors, type }) => {

  const sendBackGuest = (value, key) => {
    const final: Guest = {
      name: key === 'name' ? value : name,
      starter: key === 'starter' ? value : starter,
      main: key === 'main' ? value : main,
      desert: key === 'desert' ? value : desert,
      requirements: key === 'requirements' ? value : requirements,
      type: type
    }
    console.log(value, key)
    setInitial(false)
    handleOnChange(index, final)
  }

  const { value: name, bind: bindName, reset: resetName } = useInput(guest?.name ?? "", sendBackGuest, "name");
  const { value: starter, bind: bindStarter, reset: resetStarter } = useInput(guest?.starter ?? "", sendBackGuest, "starter");
  const { value: main, bind: bindMain, reset: resetMain } = useInput(guest?.main ?? "", sendBackGuest, "main");
  const { value: desert, bind: bindDesert, reset: resetDesert } = useInput(guest?.desert ?? "", sendBackGuest, "desert");
  const { value: requirements, bind: bindDietry, reset: resetDietry } = useInput(guest?.requirements ?? "", sendBackGuest, "requirements");

  const [isInitial, setInitial] = useState(true);

  const mapOption = (key: string, description: string, isDefault: boolean) => <option value={key} selected>{description}</option>

  return (
      <div className={rsvpStyles?.guest}>
        <div className={rsvpStyles?.spacer}>
          <label className={rsvpStyles?.label}>
            Guest Name :
          </label>
          <input className={rsvpStyles?.input} {...bindName}></input>
        </div>
        <div className={rsvpStyles?.spacer}>
          <label className={rsvpStyles?.label}>
            Starter :
          </label>
          <select className={rsvpStyles?.input} name={'starter'} {...bindStarter}>
            <option selected value={'default'}>---------</option>
            {options.starter.map((item, i) => mapOption(item?.key, item?.description, i===0))}
          </select>
          {errors.includes('starter') && !isInitial && (
            <div className={rsvpStyles.bad__selection}>You have not selected a starter</div>
          )}
        </div>
        <div className={rsvpStyles?.spacer}>
          <label className={rsvpStyles?.label}>
            Main :
          </label>
          <select className={rsvpStyles?.input} name={'main'} {...bindMain}>
          <option selected value={'default'}>---------</option>
            {options.main.map((item, i) => mapOption(item?.key, item?.description, i===0))}
           </select>
           {errors.includes('main') && !isInitial && (
            <div className={rsvpStyles.bad__selection}>You have not selected a main</div>
          )}
        </div>
        <div className={rsvpStyles?.spacer}>
          <label className={rsvpStyles?.label}>
            Desert :
          </label>
          <select className={rsvpStyles?.input} name={'desert'} {...bindDesert}>
          <option selected value={'default'}>---------</option>
            {options.desert.map((item, i) => mapOption(item?.key, item?.description, i===0))}
            </select>
            {errors.includes('desert') && !isInitial && (
            <div className={rsvpStyles.bad__selection}>You have not selected a desert</div>
          )}
        </div>
        <div className={rsvpStyles?.spacer}>
          <label className={rsvpStyles?.label}>
            Dierty requirments:
          </label>
          <input className={rsvpStyles?.input} {...bindDietry}></input>
        </div>
        <button className={classnames(rsvpStyles?.delete__button)} onClick={() => deleteGuest(index)}>Remove guest</button>
      </div>
    )
}

export default GuestView